<form class="w-full flex justify-end items-stretch" (submit)="goToSearch($event)">
  <input
    type="search"
    class="block sm:hidden w-full sm:w-40 mt-4 min-w-[100px] flex-auto rounded bg-clip-padding px-3 py-1 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:outline-none"
    [ngClass]="showInput ? 'visible' : 'hidden'"
    name="keys"
    [value]="searchText"
    [(ngModel)] = "searchText"
    (keydown.enter)="goToSearch($event)"
    placeholder="Zadejte hledané slovo"
    aria-label="Zadejte hledané slovo"
    aria-describedby="button-search-desktop"
  />
</form>
