import {Component, Input} from '@angular/core';
import {ArticleInterface} from "../../drupal/entities";

@Component({
  selector: 'app-article-small',
  templateUrl: './article-small.component.html',
  styleUrls: ['./article-small.component.css']
})
export class ArticleSmallComponent {
  @Input() article: ArticleInterface | undefined;
}
