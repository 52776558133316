import {Component, Input} from '@angular/core';
import {ArticleInterface} from "../../drupal/entities";

@Component({
  selector: 'app-article-medium',
  templateUrl: './article-medium.component.html',
  styleUrls: ['./article-medium.component.css']
})
export class ArticleMediumComponent {
  @Input() article: ArticleInterface | undefined;
}
