<div class="bg-black relative w-full h-screen fixed top-0 left-0 right-0 bottom-0 overflow-scroll">
  <a routerLink="{{backRouterLink}}" class="fixed top-6 left-6 text-white"><la-icon icon="long-arrow-alt-left"></la-icon> Zpět do článku</a>
  <div class="sm:block max-w-[1060px] mx-auto pt-20">
    <div class="p-4 sm:p-6">
      <ng-template [ngIf]="images && isBrowser" [ngIfElse]="loader">

        <lightgallery [settings]="lightGallerySettings">
          <div class="columns-1 gap-4 sm:columns-2 sm:gap-6 md:columns-3 lg:columns-3 [&>img:not(:first-child)]:mt-8">
            <ng-template [ngIf]="images && images[0]">
              <a class="gallery-item block pb-6 max-w-[300px]" attr.data-src="{{images[0].url}}" attr.data-sub-html="<h6>{{images[0].alt}}</h6><p>{{images[0].copyright}}</p>">
                <img src="{{images[0].url}}" alt="{{images[0].alt}}" loading="lazy" />
              </a>
            </ng-template>

            <div class="max-w-[300px]">
              <app-ad-position
                [positionName]="AdPositionName.square"
              ></app-ad-position>
            </div>

            <ng-template [ngIf]="images && images[1]">
              <a class="gallery-item block pb-6 max-w-[300px]" attr.data-src="{{images[1].url}}" attr.data-sub-html="<h6>{{images[1].alt}}</h6><p>{{images[1].copyright}}</p>">
                <img src="{{images[1].url}}" alt="{{images[1].alt}}" loading="lazy" />
              </a>
            </ng-template>


            <ng-template [ngIf]="images && images[2]">
              <a class="gallery-item block pb-6 max-w-[300px]" attr.data-src="{{images[2].url}}" attr.data-sub-html="<h6>{{images[2].alt}}</h6><p>{{images[2].copyright}}</p>">
                <img src="{{images[2].url}}" alt="{{images[2].alt}}" loading="lazy" />
              </a>
            </ng-template>

            <ng-template [ngIf]="images && images[3]">
              <a class="gallery-item block pb-6 max-w-[300px]" attr.data-src="{{images[3].url}}" attr.data-sub-html="<h6>{{images[3].alt}}</h6><p>{{images[3].copyright}}</p>">
                <img src="{{images[3].url}}" alt="{{images[3].alt}}" loading="lazy" />
              </a>
            </ng-template>

            <div *ngFor="let image of images; index as i" class="max-w-[300px]">
              <ng-template [ngIf]="i > 3">
                <a class="gallery-item block pb-6" attr.data-src="{{image.url}}" attr.data-sub-html="<h6>{{image.alt}}</h6><p>{{image.copyright}}</p>">
                  <img src="{{image.url}}" alt="{{image.alt}}" loading="lazy" />
                </a>
              </ng-template>
            </div>
          </div>
        </lightgallery>
      </ng-template>
    </div>
    <ng-template #loader>
      <div class="loader"><la-icon class="animate-spin" icon="spinner" size="2x"></la-icon></div>
    </ng-template>
  </div>
</div>
