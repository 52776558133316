import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class SsspService {
  sssp: any | undefined;
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.sssp = isPlatformBrowser(platformId) ? (window as any).sssp : undefined;
  }

  public setPageViewId() {
    this.sssp?.setPageViewId()
  }
}
