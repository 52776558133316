import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Drupal7Service} from "../drupal/drupal7.service";
import {Page, PageInterface, Section} from "../drupal/entities";
import {NodeResult} from "../drupal/resultTypes";
import {ActivatedRoute, Router} from "@angular/router";
import {EntityMetaService} from "../drupal/entity-meta.service";
import {AdPositionName} from "../ads/ad-position/ad-position-map";
import {AdServeServiceService} from "../ads/ad-serve/ad-serve-service.service";
import {isPlatformBrowser} from "@angular/common";
import {SsspService} from "../seznam/sssp.service";

@Component({
  selector: 'app-page-detail',
  templateUrl: './page-detail.component.html',
  styleUrls: ['./page-detail.component.css']
})
export class PageDetailComponent implements AfterViewInit {
  page: PageInterface | undefined;
  protected readonly AdPositionName = AdPositionName;
  private readonly isBrowser: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private entityMeta: EntityMetaService,
    private drupal7: Drupal7Service,
    private adServe: AdServeServiceService,
    private sssp: SsspService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit() {
    this.loadPage();

    if (this.isBrowser) {
      this.sssp.setPageViewId();

      this.adServe.serveAds([
        AdPositionName.leaderboard,
        AdPositionName.square,
        AdPositionName.stickySquare,
        AdPositionName.footerboard,
      ]);
    }
  }

  private loadPage() {
    this.route.paramMap.subscribe(params => {
      const titlePath = params.get('titlepath');
      this.drupal7.getNode(titlePath).subscribe((data: NodeResult) => {
        this.page = {
          id: data.nid,
          title: data.title,
          path: data.path,
          body: data.body.und[0].value,
        }
        this.entityMeta.setMeta(new Page(this.page));
      });
    })
  }
}
