import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {SearchStateService} from "./search/search-state.service";
import {AdServeServiceService} from "./ads/ad-serve/ad-serve-service.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'ctidoma2';
  currentRoute: string | undefined;
  isGallery: boolean | undefined;

  private readonly isBrowser: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private searchStateService: SearchStateService,
    private adServe: AdServeServiceService,
    @Inject(PLATFORM_ID) platformId: string,
  ){
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.isGallery = this.currentRoute.startsWith('/fotogalerie/');
      }
    });
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (this.isBrowser) {
        window.scrollTo(0, 0);
      }

      const searchText = this.route.snapshot.params['keys'];
      this.searchStateService.setSearchText(searchText);

      this.adServe.initAds(this.isBrowser);
    });
  }
}
