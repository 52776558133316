import {DomNode, DomParser} from "./dom-parser";

export class BrowserDomParser implements DomParser {

  private document: Document;

  constructor(
    html: string,
  ) {
    const parser = new DOMParser();
    this.document = parser.parseFromString(html, "text/html");
  }

  public querySelectorAll(selector: string): Array<DomNode> {
    return Array.from(this.document.getElementsByTagName(selector));
  }

  public get bodyHtml(): string {
    return this.document.body.innerHTML;
  }

}
