import {Component, Inject, Injector, OnInit, Optional, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from "@angular/common";
import {RESPONSE} from "@nguniversal/express-engine/tokens";

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PageNotFoundComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(RESPONSE) private response: Response,
    @Inject(Injector) private injector: Injector
  ) { }

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      const response = this.injector.get(RESPONSE);
      response.status(404);
    }
  }
}
