import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-admaster',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './admaster.component.html',
  styleUrls: ['./admaster.component.css']
})
export class AdmasterComponent {
  @Input() positionId: string | undefined;
}
