import {Component, Input} from '@angular/core';
import {ArticleInterface} from "../../drupal/entities";

@Component({
  selector: 'app-article-large',
  templateUrl: './article-large.component.html',
  styleUrls: ['./article-large.component.css']
})
export class ArticleLargeComponent {
  @Input() article: ArticleInterface | undefined;
}
