import {DomNode, DomParser} from "./dom-parser";
import parse, {HTMLElement as NodeParserHtmlElement} from "node-html-parser";

export class ServerDomParser implements DomParser {
  private document: NodeParserHtmlElement;

  constructor(
    html: string,
  ) {
    this.document = parse(html);
  }

  public querySelectorAll(selector: string): DomNode[] {
    return this.document.getElementsByTagName(selector).map(item => {
      return {
        getAttribute(attributeName: string): string | undefined | null {
          return item.getAttribute(attributeName);
        },
        appendChild(element: HTMLElement): void {
          item.appendChild(parse(element.outerHTML));
        }
      }
    })
  }

  public get bodyHtml(): string {
    return this.document.outerHTML;
  }
}
