<div class="md:grid md:grid-cols-2 space-x-6 items-center">
  <div>
    <h2 class="text-2xl text-orange-800 mb-2 text-center md:text-left md:mb-0">To nejzajímavější do Vašeho e-mailu</h2>
  </div>
  <div>
    <form (ngSubmit)="onSubmit()" #subscriptionForm="ngForm">
      <div class="flex mb-2">
        <input type="email" id="subscriptionEmail" name="subscriptionEmail" required email [(ngModel)]="subscriptionEmail" #subscriptionEmailField="ngModel" placeholder="Váš e-mail" class="form-control border border-black py-2 px-4 w-full rounded-l-full focus:outline-none" />
        <button type="submit" [disabled]="!subscriptionForm.form.valid" class="bg-black text-white py-2 px-4 rounded-r-full">Odebírat</button>
      </div>
      <div *ngIf="subscriptionEmailField.errors && (subscriptionEmailField.dirty || subscriptionEmailField.touched)" class="alert alert-error">
        <div *ngIf="subscriptionEmailField.errors['required']">E-mail je povinný.</div>
        <div *ngIf="subscriptionEmailField.errors['email']">Vyplňte správnou e-mailovou adresu.</div>
      </div>
      <div class="text-xs">
        <input type="checkbox" name="subscriptionConsent" id="subscriptionConsent" required [(ngModel)]="subscriptionConsent" #subscriptionConsentField="ngModel" class="form-control border border-black mr-1" />
        <label for="subscriptionConsent">Souhlasím s odběrem důležitých zpráv ze ČtiDoma.cz do mé e-mailové schránky.</label>
        <div *ngIf="subscriptionConsentField.errors && (subscriptionConsentField.dirty || subscriptionConsentField.touched || subscriptionEmailField.touched)" class="alert alert-error">
          <div>Souhlas s odběrem je povinný.</div>
        </div>
        <div *ngIf="response && response.status == 'error'" class="alert alert-error">
          Přihlášení k odběru newsletteru skončilo chybou. Zkuste to, prosím, později.
        </div>
        <div *ngIf="response && response.status == 'success'" class="alert alert-success">
          Děkujeme, nyní zkontrolujte svou e-mailovou schránku a potvrďte odběr newsletteru.
        </div>
      </div>
    </form>
  </div>
</div>
