<div class="mb-6 relative">
  <div class="ad-label">reklama</div>
  <div [ngStyle]="positionMinHeightClass">
    <ng-template [ngIf]="sspPositionId">
      <app-ssp [positionId]="sspPositionId"></app-ssp>
    </ng-template>
    <ng-template [ngIf]="sklikPositionId">
      <app-sklik [positionId]="sklikPositionId"></app-sklik>
    </ng-template>
    <ng-template [ngIf]="adsensePositionId">
      <app-adsense [positionId]="adsensePositionId"></app-adsense>
    </ng-template>
    <ng-template [ngIf]="admasterPositionId">
      <app-admaster [positionId]="admasterPositionId"></app-admaster>
    </ng-template>
  </div>
</div>
