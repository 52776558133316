import {Component} from '@angular/core';
import {AdProviderName} from "../ads/ad-provider/ad-provider";
import {AdPositionName} from "../ads/ad-position/ad-position-map";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {

  protected readonly AdProviderName = AdProviderName;
  protected readonly AdPositionName = AdPositionName;

}
