import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-day-info',
  template: '{{content}}',

})
export class DayInfoComponent implements OnInit {
  content: string = '';

  loadDayInfo() {
    const dateObj = new Date();
    this.content = [this.formatLongDate(dateObj), this.getNameDayFromDate(dateObj)].join(', ');
  }

  ngOnInit(): void {
    this.loadDayInfo();
  }

  private capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private formatLongDate(dateObj: Date) {
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return this.capitalizeFirstLetter(dateObj.toLocaleDateString('cs-CZ', options));
  }

  private getNameDayFromDate(dateObj: Date): string {
    const dateIndex = 'd' + (dateObj.getMonth() + 1) + '_' + dateObj.getDate();
    const nameDays: {[id: string] : string} = {
      d1_1: "Nový rok",
      d1_2: "Karina",
      d1_3: "Radmila",
      d1_4: "Diana",
      d1_5: "Dalimil",
      d1_6: "",
      d1_7: "Vilma",
      d1_8: "Čestmír",
      d1_9: "Vladan",
      d1_10: "Břetislav",
      d1_11: "Bohdana",
      d1_12: "Pravoslav",
      d1_13: "Edita",
      d1_14: "Radovan",
      d1_15: "Alice",
      d1_16: "Ctirad",
      d1_17: "Drahoslav",
      d1_18: "Vladislav",
      d1_19: "Doubravka",
      d1_20: "Ilona",
      d1_21: "Běla",
      d1_22: "Slavomír",
      d1_23: "Zdeněk",
      d1_24: "Milena",
      d1_25: "Miloš",
      d1_26: "Zora",
      d1_27: "Ingrid",
      d1_28: "Otýlie",
      d1_29: "Zdislava",
      d1_30: "Robin",
      d1_31: "Marika",
      d2_1: "Hynek",
      d2_2: "Nela",
      d2_3: "Blažej",
      d2_4: "Jarmila",
      d2_5: "Dobromila",
      d2_6: "Vanda",
      d2_7: "Veronika",
      d2_8: "Milada",
      d2_9: "Apolena",
      d2_10: "Mojmír",
      d2_11: "Božena",
      d2_12: "Slavěna",
      d2_13: "Věnceslav",
      d2_14: "Valentýn",
      d2_15: "Jiřina",
      d2_16: "Ljuba",
      d2_17: "Miloslava",
      d2_18: "Gizela",
      d2_19: "Patrik",
      d2_20: "Oldřich",
      d2_21: "Lenka",
      d2_22: "Petr",
      d2_23: "Svatopluk",
      d2_24: "Matěj",
      d2_25: "Liliana",
      d2_26: "Dorota",
      d2_27: "Alexandr",
      d2_28: "Lumír",
      d2_29: "Horymír",
      d3_1: "Bedřich",
      d3_2: "Anežka",
      d3_3: "Kamil",
      d3_4: "Stela",
      d3_5: "Kazimír",
      d3_6: "Miroslav",
      d3_7: "Tomáš",
      d3_8: "Gabriela",
      d3_9: "Františka, Rebeka",
      d3_10: "Viktorie",
      d3_11: "Anděla",
      d3_12: "Řehoř",
      d3_13: "Růžena",
      d3_14: "Rút,Matylda",
      d3_15: "Ida",
      d3_16: "Elena, Ella, Herbert",
      d3_17: "Vlastimil",
      d3_18: "Eduard",
      d3_19: "Josef",
      d3_20: "Světlana",
      d3_21: "Radek",
      d3_22: "Leona",
      d3_23: "Ivona",
      d3_24: "Gabriel",
      d3_25: "Marián",
      d3_26: "Emanuel",
      d3_27: "Dita",
      d3_28: "Soňa",
      d3_29: "Taťána",
      d3_30: "Arnošt",
      d3_31: "Kvído",
      d4_1: "Hugo",
      d4_2: "Erika",
      d4_3: "Richard",
      d4_4: "Ivana",
      d4_5: "Miroslava",
      d4_6: "Vendula",
      d4_7: "Heřman, Hermína",
      d4_8: "Ema",
      d4_9: "Dušan",
      d4_10: "Darja",
      d4_11: "Izabela",
      d4_12: "Julius",
      d4_13: "Aleš",
      d4_14: "Vincenc",
      d4_15: "Anastázie",
      d4_16: "Irena, Bernadeta",
      d4_17: "Rudolf",
      d4_18: "Valérie",
      d4_19: "Rostislav",
      d4_20: "Marcela",
      d4_21: "Alexandra",
      d4_22: "Evženie",
      d4_23: "Vojtěch",
      d4_24: "Jiří",
      d4_25: "Marek",
      d4_26: "Oto",
      d4_27: "Jaroslav",
      d4_28: "Vlastislav",
      d4_29: "Robert",
      d4_30: "Blahoslav",
      d5_1: "",
      d5_2: "Zikmund",
      d5_3: "Alexej",
      d5_4: "Květoslav",
      d5_5: "Klaudie",
      d5_6: "Radoslav",
      d5_7: "Stanislav",
      d5_8: "",
      d5_9: "Ctibor",
      d5_10: "Blažena",
      d5_11: "Svatava",
      d5_12: "Pankrác",
      d5_13: "Servác",
      d5_14: "Bonifác",
      d5_15: "Žofie",
      d5_16: "Přemysl",
      d5_17: "Aneta",
      d5_18: "Nataša",
      d5_19: "Ivo",
      d5_20: "Zbyšek",
      d5_21: "Monika",
      d5_22: "Emil",
      d5_23: "Vladimír",
      d5_24: "Jana a Vanesa",
      d5_25: "Viola",
      d5_26: "Filip",
      d5_27: "Valdemar",
      d5_28: "Vilém",
      d5_29: "Maxmilián",
      d5_30: "Ferdinand",
      d5_31: "Kamila",
      d6_1: "Laura",
      d6_2: "Jarmil",
      d6_3: "Tamara",
      d6_4: "Dalibor",
      d6_5: "Dobroslav",
      d6_6: "Norbert",
      d6_7: "Iveta, Slavoj",
      d6_8: "Medard",
      d6_9: "Stanislava",
      d6_10: "Gita",
      d6_11: "Bruno",
      d6_12: "Antonie",
      d6_13: "Antonín",
      d6_14: "Roland",
      d6_15: "Vít",
      d6_16: "Zbyněk",
      d6_17: "Adolf",
      d6_18: "Milan",
      d6_19: "Leoš",
      d6_20: "Květa",
      d6_21: "Alois",
      d6_22: "Pavla",
      d6_23: "Zdeňka",
      d6_24: "Jan",
      d6_25: "Ivan",
      d6_26: "Adriana",
      d6_27: "Ladislav",
      d6_28: "Lubomír",
      d6_29: "Petr a Pavel",
      d6_30: "Šárka",
      d7_1: "Jaroslava",
      d7_2: "Patricie",
      d7_3: "Radomír",
      d7_4: "Prokop",
      d7_5: "Cyril a Metoděj",
      d7_6: "",
      d7_7: "Bohuslava",
      d7_8: "Nora",
      d7_9: "Drahoslava",
      d7_10: "Libuše, Amálie",
      d7_11: "Olga",
      d7_12: "Bořek",
      d7_13: "Markéta",
      d7_14: "Karolína",
      d7_15: "Jindřich",
      d7_16: "Luboš",
      d7_17: "Martina",
      d7_18: "Drahomíra",
      d7_19: "Čeněk",
      d7_20: "Ilja",
      d7_21: "Vítězslav",
      d7_22: "Magdaléna",
      d7_23: "Libor",
      d7_24: "Kristýna",
      d7_25: "Jakub",
      d7_26: "Anna",
      d7_27: "Věroslav",
      d7_28: "Viktor",
      d7_29: "Marta",
      d7_30: "Bořivoj",
      d7_31: "Ignác",
      d8_1: "Oskar",
      d8_2: "Gustav",
      d8_3: "Miluše",
      d8_4: "Dominik",
      d8_5: "Kristián",
      d8_6: "Oldřiška",
      d8_7: "Lada",
      d8_8: "Soběslav",
      d8_9: "Roman",
      d8_10: "Vavřinec",
      d8_11: "Zuzana",
      d8_12: "Klára",
      d8_13: "Alena",
      d8_14: "Alan",
      d8_15: "Hana",
      d8_16: "Jáchym",
      d8_17: "Petra",
      d8_18: "Helena",
      d8_19: "Ludvík",
      d8_20: "Bernard",
      d8_21: "Johana",
      d8_22: "Bohuslav",
      d8_23: "Sandra",
      d8_24: "Bartoloměj",
      d8_25: "Radim",
      d8_26: "Luděk",
      d8_27: "Otakar",
      d8_28: "Augustýn",
      d8_29: "Evelína",
      d8_30: "Vladěna",
      d8_31: "Pavlína",
      d9_1: "Linda, Samuel",
      d9_2: "Adéla",
      d9_3: "Bronislav",
      d9_4: "Jindřiška",
      d9_5: "Boris",
      d9_6: "Boleslav",
      d9_7: "Regína",
      d9_8: "Mariana",
      d9_9: "Daniela",
      d9_10: "Irma",
      d9_11: "Denisa",
      d9_12: "Marie",
      d9_13: "Lubor",
      d9_14: "Radka",
      d9_15: "Jolana",
      d9_16: "Ludmila",
      d9_17: "Naděžda",
      d9_18: "Kryštof",
      d9_19: "Zita",
      d9_20: "Oleg",
      d9_21: "Matouš",
      d9_22: "Darina",
      d9_23: "Berta",
      d9_24: "Jaromír",
      d9_25: "Zlata",
      d9_26: "Andrea",
      d9_27: "Jonáš",
      d9_28: "Václav",
      d9_29: "Michal, Michael",
      d9_30: "Jeroným",
      d10_1: "Igor",
      d10_2: "Olívie, Oliver",
      d10_3: "Bohumil",
      d10_4: "František",
      d10_5: "Eliška",
      d10_6: "Hanuš",
      d10_7: "Justýna",
      d10_8: "Věra",
      d10_9: "Štefan, Sára",
      d10_10: "Marina",
      d10_11: "Andrej",
      d10_12: "Marcel",
      d10_13: "Renáta",
      d10_14: "Agáta",
      d10_15: "Tereza",
      d10_16: "Havel",
      d10_17: "Hedvika",
      d10_18: "Lukáš",
      d10_19: "Michala, Michaela",
      d10_20: "Vendelín",
      d10_21: "Brigita",
      d10_22: "Sabina",
      d10_23: "Teodor",
      d10_24: "Nina",
      d10_25: "Beáta",
      d10_26: "Erik",
      d10_27: "Šarlota, Zoe",
      d10_28: "",
      d10_29: "Silvie, Sylva",
      d10_30: "Tadeáš",
      d10_31: "Štěpánka",
      d11_1: "Felix",
      d11_2: "",
      d11_3: "Hubert",
      d11_4: "Karel, Karla",
      d11_5: "Miriam",
      d11_6: "Liběna",
      d11_7: "Saskie",
      d11_8: "Bohumír",
      d11_9: "Bohdan",
      d11_10: "Evžen",
      d11_11: "Martin",
      d11_12: "Benedikt",
      d11_13: "Tibor",
      d11_14: "Sáva",
      d11_15: "Leopold",
      d11_16: "Otmar",
      d11_17: "Mahulena",
      d11_18: "Romana",
      d11_19: "Alžběta",
      d11_20: "Nikola",
      d11_21: "Albert",
      d11_22: "Cecílie",
      d11_23: "Klement",
      d11_24: "Emílie",
      d11_25: "Kateřina",
      d11_26: "Artur",
      d11_27: "Xenie",
      d11_28: "René",
      d11_29: "Zina",
      d11_30: "Ondřej",
      d12_1: "Iva",
      d12_2: "Blanka",
      d12_3: "Svatoslav",
      d12_4: "Barbora",
      d12_5: "Jitka",
      d12_6: "Mikuláš",
      d12_7: "Ambrož",
      d12_8: "Květoslava",
      d12_9: "Vratislav",
      d12_10: "Julie",
      d12_11: "Dana",
      d12_12: "Simona",
      d12_13: "Lucie",
      d12_14: "Lýdie",
      d12_15: "Radana, Radan",
      d12_16: "Albína",
      d12_17: "Daniel",
      d12_18: "Miloslav",
      d12_19: "Ester",
      d12_20: "Dagmar",
      d12_21: "Natálie",
      d12_22: "Šimon",
      d12_23: "Vlasta",
      d12_24: "Adam a Eva",
      d12_25: "Boží hod vánoční",
      d12_26: "Štěpán, 2. svátek vánoční",
      d12_27: "Žaneta",
      d12_28: "Bohumila",
      d12_29: "Judita",
      d12_30: "David",
      d12_31: "Silvestr",
    }

    return nameDays[dateIndex];
  }
}
