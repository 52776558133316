import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ArticleInterface, Page} from "../../drupal/entities";
import {ActivatedRoute, Router} from "@angular/router";
import {Drupal7Service} from "../../drupal/drupal7.service";
import {Drupal7mappingService} from "../../drupal/drupal7mapping.service";
import {tap} from "rxjs";
import {ArticleResult} from "../../drupal/resultTypes";
import {SearchStateService} from "../search-state.service";
import {AdPositionName} from "../../ads/ad-position/ad-position-map";
import {AdServeServiceService} from "../../ads/ad-serve/ad-serve-service.service";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {EntityMetaService} from "../../drupal/entity-meta.service";
import {SsspService} from "../../seznam/sssp.service";

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})

export class SearchResultsComponent implements OnInit, AfterViewInit {
  searchInProgress: boolean = false;
  searchResults: Array<ArticleInterface> | undefined;
  protected readonly AdPositionName = AdPositionName;
  private readonly isBrowser: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private searchStateService: SearchStateService,
    private drupal7: Drupal7Service,
    private drupal7mapping: Drupal7mappingService,
    private adServe: AdServeServiceService,
    private entityMeta: EntityMetaService,
    private sssp: SsspService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (!this.isBrowser) {
      const searchPage: Page = new Page({
        id: 0,
        title: 'Výsledky vyhledávání',
        path: '',
        fullUrl: this.router.url
      });
      this.entityMeta.setMeta(searchPage);
    }
  }

  ngAfterViewInit() {
    this.loadSearchResults();

    if (this.isBrowser) {
      this.sssp.setPageViewId();

      this.adServe.serveAds([
        AdPositionName.leaderboard,
        AdPositionName.square,
        AdPositionName.stickySquare,
        AdPositionName.footerboard,
      ]);
    }
  }

  private loadSearchResults() {
    const searchText = this.route.snapshot.params['keys'];
    this.searchStateService.setSearchText(searchText);
    if (!this.searchInProgress) {
      this.searchInProgress = true;
      this.drupal7.getSearchResults({searchText: searchText}).pipe(
        tap((data: Array<ArticleResult>) => {
          this.searchResults = this.drupal7mapping.mapArticles(data);
        })
      ).subscribe((data: Array<ArticleResult>) => {
        this.searchInProgress = false;
      })
    }
  }
}
