import {AfterViewInit, Component, Inject, PLATFORM_ID} from '@angular/core';
import {Article, ArticleInterface} from "../../drupal/entities";
import {ArticleResult} from "../../drupal/resultTypes";
import {ActivatedRoute} from "@angular/router";
import {Drupal7Service} from "../../drupal/drupal7.service";
import {Drupal7mappingService} from "../../drupal/drupal7mapping.service";
import {EntityMetaService} from "../../drupal/entity-meta.service";
import {AdPositionName} from "../../ads/ad-position/ad-position-map";
import {AdServeServiceService} from "../../ads/ad-serve/ad-serve-service.service";
import {isPlatformBrowser} from "@angular/common";
import {SsspService} from "../../seznam/sssp.service";

@Component({
  selector: 'app-article-discussion',
  templateUrl: './article-discussion.component.html',
  styleUrls: ['./article-discussion.component.css']
})
export class ArticleDiscussionComponent implements AfterViewInit {
  article: ArticleInterface | undefined;
  protected readonly AdPositionName = AdPositionName;

  private readonly isBrowser: boolean;

  constructor(
    private route: ActivatedRoute,
    private entityMeta: EntityMetaService,
    private drupal7: Drupal7Service,
    private drupal7mapping: Drupal7mappingService,
    private adServe: AdServeServiceService,
    private sssp: SsspService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const titlePath = params.get('titlepath');
      if (titlePath) {
        // @ts-ignore
        this.drupal7.getArticle(titlePath).subscribe((data: ArticleResult) => {
          this.article = this.drupal7mapping.mapArticle(data);
          this.entityMeta.setMeta(new Article(this.article));

          if (this.isBrowser) {
            let szn = (window as any).SZN;
            szn.DiscussionLoader.load(['embedded']).then(() => {
              const instance = szn.Discussion.create(
                "ctidoma", // identifier of your service
                "#discussion",
                {
                  // configuration
                }
              );

              instance.loadDiscussion(
                "ctidoma.cz/node/{{article.id}}",
                {   // meta data
                  title: "{{article.title}}",
                  url: "{{article.fullUrl}}"
                }
              );
            });
          }
        });
      }
    });
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this.sssp.setPageViewId();

      this.adServe.serveAds([
        AdPositionName.leaderboard,
        AdPositionName.square,
        AdPositionName.stickySquare,
        AdPositionName.footerboard,
      ]);
    }
  }
}
