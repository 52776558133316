import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {Drupal7Service} from "../drupal/drupal7.service";
import {environment} from "../../environments/environment.prod";
import {ArticleResult, NodeResult} from "../drupal/resultTypes";
import {Drupal7mappingService} from "../drupal/drupal7mapping.service";
import {Article} from "../drupal/entities";

@Component({
  selector: 'app-redirect-negotiator',
  templateUrl: './redirect-negotiator.component.html',
  styleUrls: ['./redirect-negotiator.component.css']
})
export class RedirectNegotiatorComponent {
  constructor(
    private router: Router,
    private drupal7: Drupal7Service,
    private drupal7mapping: Drupal7mappingService
  ) {
    let url = this.router.url;
    let trimmedUrl = url.replace(/^\/|\/$/g, '');
    this.drupal7.getRedirect(trimmedUrl).subscribe(response => {
      if (response.status == 'success' && response.path) {
        /**
         * e.g. historie/amerika-necinne-prihlizela-holocaustu-mohla-zachranit-miliony-zidu-ale-neprijala-je-65716
         * redirect to:
         * clanek/osobnosti/cyril-höschl-diky-strachu-tu-stale-jeste-jsme-ale-dojit-si-pro-prasek-na-strach-z-migrantu-nejde-30115
         */
        let args = response.path.split('/');
        if (args[0] === 'node') {
          this.drupal7.getNodeById(args[1]).subscribe((data) => {
            if (data.type == 'article') {
              let article = this.drupal7mapping.mapArticle(data);
              this.router.navigate([article.routerLink]);
            }
          });
        }
        else if (args[0] === 'taxonomy') {
          this.drupal7.getTaxonomyTermById(args[2]).subscribe((data) => {
            if (data.vocabulary_machine_name == 'rubriky') {
              let section = this.drupal7mapping.mapSection(data);
              this.router.navigate([section.routerLink]);
            }
            else if (data.vocabulary_machine_name == 'temata') {
              let tag = this.drupal7mapping.mapTag(data);
              this.router.navigate([tag.routerLink]);
            }
          });
        }
      }
      else if (response.status == 'success') {
        let targetPath = response.redirect.replace(environment.baseUrl, '').replace(/^\/|\/$/g, '');
        this.router.navigate([targetPath])
      }
      else {
        this.router.navigate(['404'])
      }
    });
  }
}
