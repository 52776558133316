import { Component } from '@angular/core';
import {SearchStateService} from "../search-state.service";

@Component({
  selector: 'app-mobile-search-btn',
  templateUrl: './mobile-search-btn.component.html',
  styleUrls: ['./mobile-search-btn.component.css']
})
export class MobileSearchBtnComponent {
  constructor(private searchStateService: SearchStateService) { }

  toggleSearchInput(): void {
    this.searchStateService.toggleSearchInput();
  }
}
