<form class="w-full flex justify-end items-stretch" (submit)="goToSearch($event)">
  <input
    type="search"
    class="absolute -bottom-10 w-full sm:bottom-0 sm:top-0 sm:relative sm:w-40 m-0 min-w-[100px] flex-auto rounded bg-clip-padding px-3 py-1 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:outline-none"
    [ngClass]="searchVisible ? 'visible' : 'hidden'"
    name="keys"
    [value]="searchText"
    [(ngModel)] = "searchText"
    (keydown.enter)="goToSearch($event)"
    placeholder="Zadejte hledané slovo"
    aria-label="Zadejte hledané slovo"
    aria-describedby="button-search-desktop"
  />

  <button
    class="z-[2] right-0 inset-y-0 rounded-full p-2 text-xs font-medium uppercase leading-tight transition duration-150 ease-in-out text-black hover:text-orange-800"
    type="button"
    id="button-search-desktop"
    (click)="toggleSearchInput()"
  >
    <la-icon icon="search" size="2x"></la-icon>
  </button>
</form>
