import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ArticleInterface} from "../../drupal/entities";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-article-teaser',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './article-teaser.component.html',
  styleUrls: ['./article-teaser.component.css']
})
export class ArticleTeaserComponent {
  @Input() article: ArticleInterface | undefined;
}
