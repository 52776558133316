import {Component, OnInit, Input, Inject, PLATFORM_ID, AfterViewInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {Drupal7Service} from "../drupal/drupal7.service";
import {MenuItemInterface} from "../drupal/entities";
import {MenuTreeResult} from "../drupal/resultTypes";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements AfterViewInit {
  @Input() isDesktop: boolean = true;
  menuItems: Array<MenuItemInterface> = [];
  paramMap: ParamMap | undefined;
  private readonly isBrowser: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private drupal7: Drupal7Service,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit() {
    this.loadMenu();
  }

  private loadMenu() {
    this.route.paramMap.subscribe(params => {
      this.paramMap = params;
      this.drupal7.getMainMenu().subscribe((data: MenuTreeResult) => this.processMenuTree(data));
    });
  }

  private processMenuTree(data: MenuTreeResult) {
    Object.entries(data.tree).forEach(([key, menuItemResult]) => {
      if (menuItemResult.link.hidden === "0") {
        this.menuItems.push({
          id: menuItemResult.link.mlid,
          title: menuItemResult.link.title,
          path: this.sanitizePath(menuItemResult.link.path_alias, menuItemResult.link.path),
          isExternal: menuItemResult.link.external,
          isActive: this.processIsActive(this.sanitizePath(menuItemResult.link.path_alias, menuItemResult.link.path))
        });
      }
    });
  }

  private sanitizePath(pathAlias: string, path: string): string {
    if (path == 'node/2') {
      return 'stranka/kontakty-2';
    }
    return pathAlias;
  }

  private processIsActive(path: string): boolean {
    let currentPath = this.paramMap?.keys
    return false;
  }
}
