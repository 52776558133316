import { Component } from '@angular/core';
import {ArticleInterface} from "../drupal/entities";
import {Drupal7Service} from "../drupal/drupal7.service";
import {ArticleResult} from "../drupal/resultTypes";
import {Drupal7mappingService} from "../drupal/drupal7mapping.service";

@Component({
  selector: 'app-most-read',
  templateUrl: './most-read.component.html',
  styleUrls: ['./most-read.component.css']
})
export class MostReadComponent {
  articles: Array<ArticleInterface> | undefined;

  constructor(
    private drupal7: Drupal7Service,
    private drupal7mapping: Drupal7mappingService
  ) {}

  ngOnInit(): void {
    this.drupal7.getViewArticles({viewName: 'most_reads', displayId: 'services_1', limit: 8}).subscribe((data: Array<ArticleResult>) => {
      this.articles = this.drupal7mapping.mapArticles(data);
    });
  }
}
