<ng-template [ngIf]="isDesktop" [ngIfElse]="isMobile">
  <a *ngFor="let menuItem of menuItems" routerLink="{{ menuItem.path }}" class="text-black rounded-full hover:bg-orange-800 hover:text-white hover:no-underline px-3 py-2 text-sm font-medium" routerLinkActive="bg-orange-800 text-white">
    {{ menuItem['title'] }}
  </a>
</ng-template>
<ng-template #isMobile>
  <div id="menu-mobile">
    <a *ngFor="let menuItem of menuItems" routerLink="{{ menuItem.path }}" class="text-black block rounded-full px-3 py-2 text-base font-medium" routerLinkActive="bg-orange-800 text-white">
      {{ menuItem['title'] }}
    </a>
  </div>
</ng-template>
