import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-gallery-root',
  templateUrl: './app-gallery.component.html',
})

export class AppGalleryComponent {
  private readonly isBrowser: boolean;
  title = 'ctidoma2';

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      const adEl = document.getElementById("branding");
      if (adEl) {
        adEl.classList.add("adFullBackup");
        adEl.classList.remove("adFull");
      }
    }
  }
}
