import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {SeznamPocitadlolibiseComponent} from "./seznam-pocitadlolibise/seznam-pocitadlolibise.component";



@NgModule({
  declarations: [SeznamPocitadlolibiseComponent],
  exports: [SeznamPocitadlolibiseComponent],
  imports: [
    CommonModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SeznamModule { }
