import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SearchStateService} from "../search-state.service";

@Component({
  selector: 'app-searchform',
  templateUrl: './searchform.component.html',
  styleUrls: ['./searchform.component.css']
})
export class SearchformComponent {
  searchVisible: boolean = false;
  searchText: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private searchStateService: SearchStateService
  ) {
    // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  toggleSearchInput(){
    this.searchVisible = !this.searchVisible;
  }

  goToSearch(event: any) {
    const searchKeys = this.searchText;
    if (searchKeys) {
      this.router.navigate(['search', searchKeys]);
    }
  }

  ngOnInit(): void {
    this.searchStateService.currentSearchInputState.subscribe(show => {
      this.searchVisible = show;
    });
    this.searchStateService.currentSearchText.subscribe(searchText => {
      this.searchText = searchText;
      this.searchVisible = !!this.searchText;
    });
  }
}
