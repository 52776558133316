import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-seznam-pocitadlolibise',
  templateUrl: './seznam-pocitadlolibise.component.html',
  styleUrls: ['./seznam-pocitadlolibise.component.css']
})
export class SeznamPocitadlolibiseComponent {
  @Input() url: string | undefined;
}
