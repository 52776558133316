import {Inject, Injectable} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import { DOCUMENT } from '@angular/common';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class EntityMetaService {

  constructor(
    private router: Router,
    private titleService:Title,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document
  ) { }

  public setMeta(entity: any) {
    if (typeof entity.getTitle === 'function') {
      this.titleService.setTitle(entity.getTitle() + ' | ČtiDoma.cz');
      this.meta.addTag({ property: 'og:title', 'content': entity.getTitle() });
    }
    if (typeof entity.getMetaDescription === 'function') {
      this.meta.addTag({ name: 'description', 'content': entity.getMetaDescription() });
      this.meta.addTag({ property: 'og:description', 'content': entity.getMetaDescription() });
    }
    if (typeof entity.getOgType === 'function') {
      this.meta.addTag({ property: 'og:type', 'content': entity.getOgType() });
    }
    if (typeof entity.getImage === 'function' && entity.getImage()) {
      this.meta.addTag({ property: 'og:image', 'content': entity.getImage().largeUrl });
    }

    const canonicalLink = this.document.querySelector('link[rel="canonical"]');
    if (canonicalLink && typeof entity.getFullUrl === 'function') {
      canonicalLink.setAttribute('href', entity.getFullUrl());

      this.meta.addTag({ property: 'og:url', 'content': entity.getFullUrl() });
    } else if (canonicalLink) {
      canonicalLink.setAttribute('href', this.router.url);

      this.meta.addTag({ property: 'og:url', 'content': this.router.url });
    } else if (typeof entity.getFullUrl === 'function') {
      let link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', entity.getFullUrl());
      this.document.head.appendChild(link);

      this.meta.addTag({ property: 'og:url', 'content': entity.getFullUrl() });
    }
  }
}
