import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SearchStateService {
  private showSearchInput = new BehaviorSubject<boolean>(false);
  private searchText = new BehaviorSubject<string>('');

  currentSearchInputState = this.showSearchInput.asObservable();
  currentSearchText = this.searchText.asObservable();
  constructor() { }

  toggleSearchInput(): void {
    this.showSearchInput.next(!this.showSearchInput.value);
  }

  setSearchText(searchKeywords: string): void {
    this.searchText.next(searchKeywords);
  }
}
