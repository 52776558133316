import {NgModule} from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { FrontpageComponent } from './frontpage/frontpage.component';
import { ArticleDetailComponent } from './article/article-detail/article-detail.component';
import { AngularLineawesomeModule, LaIconLibrary } from 'angular-line-awesome';
import {
  labFacebookF,
  labTwitter, lasCheck, lasComments,
  lasImages,
  lasInfoCircle, lasLongArrowAltLeft,
  lasSadTear,
  lasSearch,
  lasSpinner
} from 'angular-line-awesome/icons';
import { HttpClientModule } from "@angular/common/http";

import { RouterModule } from "@angular/router";
import { AppRoutingModule } from './app-routing.module';
import { DayInfoComponent } from './day-info.component';
import { MainMenuComponent } from "./main-menu/main-menu.component";
import { PageNotFoundComponent } from './pagenotfound/pagenotfound.component';
import { PageDetailComponent } from './page-detail/page-detail.component';
import { TagDetailComponent } from './tag-detail/tag-detail.component';
import { SectionDetailComponent} from "./section-detail/section-detail.component";
import { SidebarComponent } from './sidebar/sidebar.component';
import {ArticleTeaserComponent} from "./article/article-teaser/article-teaser.component";
import { MostReadComponent } from './most-read/most-read.component';
import { ArticleSmallComponent } from './article/article-small/article-small.component';
import {ArticleLargeComponent} from "./article/article-large/article-large.component";
import {ArticleBoxComponent} from "./article/article-box/article-box.component";
import {ArticleMediumComponent} from "./article/article-medium/article-medium.component";
import {SearchformComponent} from "./search/searchform/searchform.component";
import {SearchResultsComponent} from "./search/search-results/search-results.component";
import {AppGalleryModule} from "./app-gallery.module";
import {AppGalleryComponent} from "./app-gallery.component";
import {AppMagazineComponent} from "./app-magazine.component";
import {AppMagazineModule} from "./app-magazine/app-magazine.module";
import {SeznamModule} from "./seznam/seznam.module";
import {ArticleDiscussionComponent} from "./article/article-discussion/article-discussion.component";
import {NewsletterSubscriptionComponent} from "./newsletter-subscription/newsletter-subscription.component";
import {FormsModule} from "@angular/forms";
import {MobileSearchBtnComponent} from "./search/mobile-search-btn/mobile-search-btn.component";
import {MobileSearchInputComponent} from "./search/mobile-search-input/mobile-search-input.component";
import {NewsletterConfirmationComponent} from "./newsletter-confirmation/newsletter-confirmation.component";
import {SafeHtmlPipe} from "./safe-html";
import { PagingComponent } from './paging/paging.component';
import {AuthorDetailComponent} from "./author-detail/author-detail.component";
import {AdZdechovskyComponent} from "./ads/ad-zdechovsky/ad-zdechovsky.component";

@NgModule({
    declarations: [
        AppComponent,
        AppGalleryComponent,
        AppMagazineComponent,
        AuthorDetailComponent,
        FrontpageComponent,
        ArticleDetailComponent,
        ArticleDiscussionComponent,
        DayInfoComponent,
        MainMenuComponent,
        PageNotFoundComponent,
        PageDetailComponent,
        TagDetailComponent,
        SectionDetailComponent,
        SidebarComponent,
        MostReadComponent,
        ArticleSmallComponent,
        ArticleLargeComponent,
        ArticleBoxComponent,
        ArticleMediumComponent,
        SearchformComponent,
        SearchResultsComponent,
        MobileSearchBtnComponent,
        MobileSearchInputComponent,
        NewsletterConfirmationComponent,
        NewsletterSubscriptionComponent,
        SafeHtmlPipe,
        PagingComponent,
        AdZdechovskyComponent
    ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    AppGalleryModule,
    AppMagazineModule,
    AngularLineawesomeModule,
    HttpClientModule,
    ArticleTeaserComponent,
    SeznamModule,
    FormsModule,
  ],
  providers: [ provideClientHydration() ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: LaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons([lasSadTear, lasSearch, lasInfoCircle, lasImages, labFacebookF, labTwitter, lasSpinner, lasComments, lasLongArrowAltLeft, lasCheck]);
  }
}
