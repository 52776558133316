import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {AdPositionName} from "./ads/ad-position/ad-position-map";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-magazine-root',
  templateUrl: './app-magazine.component.html',
})

export class AppMagazineComponent {
  title = 'ctidoma2';
  mobileMenuHidden = true;
  protected readonly AdPositionName = AdPositionName;

  private readonly isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      const adEl = document.getElementById("branding");
      if (adEl && adEl.classList.contains('adFullBackup')) {
        adEl.classList.remove("adFullBackup");
        adEl.classList.add("adFull");
      }
    }
  }

}
