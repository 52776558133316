import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SearchStateService} from "../search-state.service";

@Component({
  selector: 'app-mobile-search-input',
  templateUrl: './mobile-search-input.component.html',
  styleUrls: ['./mobile-search-input.component.css']
})
export class MobileSearchInputComponent implements OnInit {
  searchText: string | null | undefined;
  showInput = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private searchStateService: SearchStateService
  ) { }

  goToSearch(event: any) {
    const searchKeys = this.searchText;
    if (searchKeys) {
      this.searchStateService.setSearchText(searchKeys);
      this.router.navigate(['search', searchKeys]);
    }
  }

  ngOnInit(): void {
    this.searchStateService.currentSearchInputState.subscribe(show => {
      this.showInput = show;
    });
    this.searchStateService.currentSearchText.subscribe(searchText => {
      this.searchText = searchText;
      this.showInput = !!this.searchText;
    });
  }
}
