import { Component } from '@angular/core';
import {Drupal7Service} from "../drupal/drupal7.service";

@Component({
  selector: 'app-newsletter-subscription',
  templateUrl: './newsletter-subscription.component.html',
  styleUrls: ['./newsletter-subscription.component.css']
})
export class NewsletterSubscriptionComponent {
  response: any;
  subscriptionEmail: string|undefined;
  subscriptionConsent: boolean|undefined;


  constructor(
    private drupal7: Drupal7Service
  ) {}

  onSubmit() {
    if (this.subscriptionEmail && this.subscriptionConsent) {
      this.drupal7.subscribeToNewsletter(this.subscriptionEmail).subscribe(response => {
        this.response = response;
      });
    }
    else {
      this.response = {'status': 'error'};
    }
  }
}
