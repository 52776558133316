<div class="max-w-[1060px] mx-auto">
  <div class="block md:flex p-6 md:space-x-12">
    <div class="w-full md:w-2/3 md:flex-1">
      <ng-template [ngIf]="page" [ngIfElse]="loader">
        <h1 class="text-3xl text-orange-800 font-bold mb-6">{{page.title}}</h1>
        <div innerHTML="{{page.body}}"></div>
      </ng-template>
      <ng-template #loader>
        <div class="loader"><la-icon class="animate-spin" icon="spinner" size="2x"></la-icon></div>
      </ng-template>
    </div>
    <div class="w-full md:w-1/3 md:max-w-[300px] flex items-stretch">
      <app-sidebar class="relative w-full"></app-sidebar>
    </div>
  </div>
</div>
