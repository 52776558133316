<div id="sklik-zone-{{positionId}}">
  <ng-template [ngIf]="positionId == '300x300'">
    <!-- ad 300x600 -->
    <img src="https://napoveda.sklik.cz/wp-content/uploads/2020/06/300x300_sklik.jpg" loading="lazy" />
  </ng-template>
  <ng-template [ngIf]="positionId == '300x600'">
    <!-- ad 300x600 -->
    <img src="https://napoveda.sklik.cz/wp-content/uploads/2020/06/300x600_sklik.jpg" loading="lazy" />
  </ng-template>
  <ng-template [ngIf]="positionId == '480x300'">
    <!-- ad 480x300 -->
    <img src="https://napoveda.sklik.cz/wp-content/uploads/2020/06/480x300_sklik.jpg" loading="lazy" />
  </ng-template>
</div>
