import {AfterViewInit, Component, Inject, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {EntityMetaService} from "../../drupal/entity-meta.service";
import {Drupal7Service} from "../../drupal/drupal7.service";
import {Drupal7mappingService} from "../../drupal/drupal7mapping.service";
import {AdServeServiceService} from "../ad-serve/ad-serve-service.service";
import {SsspService} from "../../seznam/sssp.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-ad-zdechovsky',
  templateUrl: './ad-zdechovsky.component.html',
  styleUrls: ['./ad-zdechovsky.component.css']
})
export class AdZdechovskyComponent implements AfterViewInit {
  hideBanner: boolean = true;

  private readonly isBrowser: boolean;

  constructor(
    private sssp: SsspService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.sssp = this.isBrowser ? (window as any).sssp : undefined;
  }

  ngAfterViewInit() {
    if (this.isBrowser && typeof (window as any).sssp !== 'undefined' && !(window as any).sssp.displaySeznamAds()) {
      this.hideBanner = false;
    }
  }

}
