import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BrowserDomParser} from "./browser-dom.parser";
import {DomParser} from "./dom-parser";
import {isPlatformBrowser} from "@angular/common";
import {ServerDomParser} from "./server-dom.parser";

@Injectable({
  providedIn: 'root'
})
export class DomParserFactoryService {
  constructor(
    @Inject(PLATFORM_ID)
    private readonly platformId: string,
  ) {
  }

  public parse(html: string): DomParser {
    if (isPlatformBrowser(this.platformId)) {
      return new BrowserDomParser(html);
    }

    return new ServerDomParser(html);
  }
}
