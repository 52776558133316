import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {GalleryDetailComponent} from "./gallery-detail/gallery-detail.component";
import { AngularLineawesomeModule, LaIconLibrary } from 'angular-line-awesome';
import {
  lasLongArrowAltLeft
} from 'angular-line-awesome/icons';
import {BrowserModule, provideClientHydration} from "@angular/platform-browser";
import {AppRoutingModule} from "./app-routing.module";
import {HttpClientModule} from "@angular/common/http";
import {ArticleTeaserComponent} from "./article/article-teaser/article-teaser.component";
import {AdsenseComponent} from "./ads/ad-provider/adsense/adsense.component";
import {AdmasterComponent} from "./ads/ad-provider/admaster/admaster.component";
import {SspComponent} from "./ads/ad-provider/ssp/ssp.component";
import {SklikComponent} from "./ads/ad-provider/sklik/sklik.component";
import {AppGalleryComponent} from "./app-gallery.component";
import {PageNotFoundComponent} from "./pagenotfound/pagenotfound.component";
import {AdPositionComponent} from "./ads/ad-position/ad-position.component";
import {LightgalleryModule} from "lightgallery/angular";
import {RedirectNegotiatorComponent} from "./redirect-negotiator/redirect-negotiator.component";

const routes: Routes = [
  {
    path: 'fotogalerie/:titlepath',
    component: GalleryDetailComponent,
    title: 'Fotogalerie | ČtiDoma.cz',
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    title: 'Stránka nenalezena | ČtiDoma.cz',
  },
  {
    path: '**',
    component: RedirectNegotiatorComponent,
  }
];

@NgModule({
  declarations: [
    GalleryDetailComponent,
    AdPositionComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    BrowserModule,
    RouterModule,
    AngularLineawesomeModule,
    HttpClientModule,
    ArticleTeaserComponent,
    SspComponent,
    SklikComponent,
    AdsenseComponent,
    AdmasterComponent,
    LightgalleryModule
  ],
  providers: [provideClientHydration()],
  exports: [
    AdPositionComponent
  ],
  bootstrap: []
})
export class AppGalleryModule {
  constructor(library: LaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons([lasLongArrowAltLeft]);
  }
}
