import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class BrowserLoggerService {
  private readonly isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public log(...args: any[]) {
    if (this.isBrowser) {
      console.log(...args);
    }
  }

  public error(...args: any[]) {
    if (this.isBrowser) {
      console.error(...args);
    }
  }

  public warn(...args: any[]) {
    if (this.isBrowser) {
      console.warn(...args);
    }
  }
}
