import {sklikPositionId} from "../ad-provider/sklik/sklik.component";
import {adsensePositionId} from "../ad-provider/adsense/adsense.component";
import {sspPositionId} from "../ad-provider/ssp/ssp.component";

export enum AdPositionName {
  leaderboard = 'leaderboard',
  square = 'square', // 300x300
  stickySquare = 'stickySquare', // 300x600
  stickyRectangle = 'stickyRectangle', // 480x300
  footerboard = 'footerboard', // 970x250
  wallpaper = 'wallpaper', // 480x300
  smrMobilClanek = 'smrMobilClanek',
  smrMobilClanek2 = 'smrMobilClanek2',
  context = 'context',
  mobileBottomSticky = 'mobileBottomSticky',
  native = 'native',
  nativeMobile = 'nativeMobile',
  articlePerex = 'articlePerex',
  articleText = 'articleText',
  articleBottom = 'articleBottom',
}

export interface AdProviderInterface {
  name: AdPositionName;
  sklikPositionDesktop: sklikPositionId | undefined;
  sklikPositionMobile: sklikPositionId | undefined;
  adsensePositionDesktop: adsensePositionId | undefined;
  adsensePositionMobile: adsensePositionId | undefined;
  sspPositionDesktop: sspPositionId | undefined;
  sspPositionMobile: sspPositionId | undefined;
  minHeight: number;
}

export interface AdInterface {
  id: string,
  width: number | undefined,
  height: number | undefined,
  isMobile: boolean | undefined,
  isDesktop: boolean | undefined,
}

export class AdPositions {
  map: { [id: string]: AdProviderInterface } = {
    'leaderboard': {
      name: AdPositionName.leaderboard,
      sklikPositionDesktop: sklikPositionId.leaderboard,
      sklikPositionMobile: sklikPositionId.leaderboard,
      adsensePositionDesktop: undefined,
      adsensePositionMobile: undefined,
      sspPositionDesktop: sspPositionId.leaderboard,
      sspPositionMobile: sspPositionId.leaderboard,
      minHeight: 210,
    },

    'square': {
      name: AdPositionName.square,
      sklikPositionDesktop: sklikPositionId.smr,
      sklikPositionMobile: sklikPositionId.smrMobil,
      adsensePositionDesktop: adsensePositionId.square,
      adsensePositionMobile: adsensePositionId.square,
      sspPositionDesktop: sspPositionId.smr,
      sspPositionMobile: sspPositionId.smrMobil,
      minHeight: 300,
    },

    'stickySquare': {
      name: AdPositionName.stickySquare,
      sklikPositionDesktop: sklikPositionId.skyscraper,
      sklikPositionMobile: undefined,
      adsensePositionDesktop: adsensePositionId.skyscraper,
      adsensePositionMobile: adsensePositionId.skyscraper,
      sspPositionDesktop: sspPositionId.skyscraper,
      sspPositionMobile: undefined,
      minHeight: 300,
    },

    'stickyRectangle': {
      name: AdPositionName.stickyRectangle,
      sklikPositionDesktop: sklikPositionId.skyscraper,
      sklikPositionMobile: undefined,
      adsensePositionDesktop: adsensePositionId.wallpaper,
      adsensePositionMobile: adsensePositionId.wallpaper,
      sspPositionDesktop: sspPositionId.skyscraper,
      sspPositionMobile: undefined,
      minHeight: 300,
    },

    'smrMobilClanek': {
      name: AdPositionName.smrMobilClanek,
      sklikPositionDesktop: undefined,
      sklikPositionMobile: sklikPositionId.smrMobilClanek,
      adsensePositionDesktop: undefined,
      adsensePositionMobile: undefined,
      sspPositionDesktop: undefined,
      sspPositionMobile: sspPositionId.smrMobilClanek,
      minHeight: 300,
    },

    'smrMobilClanek2': {
      name: AdPositionName.smrMobilClanek2,
      sklikPositionDesktop: undefined,
      sklikPositionMobile: sklikPositionId.smrMobilClanek2,
      adsensePositionDesktop: undefined,
      adsensePositionMobile: undefined,
      sspPositionDesktop: undefined,
      sspPositionMobile: sspPositionId.smrMobilClanek2,
      minHeight: 300,
    },

    'articlePerex': {
      name: AdPositionName.articlePerex,
      sklikPositionDesktop: sklikPositionId.wallpaper,
      sklikPositionMobile: sklikPositionId.smrMobilClanek,
      adsensePositionDesktop: undefined,
      adsensePositionMobile: undefined,
      sspPositionDesktop: sspPositionId.wallpaper,
      sspPositionMobile: sspPositionId.smrMobilClanek,
      minHeight: 300,
    },

    'articleText': {
      name: AdPositionName.articleText,
      sklikPositionDesktop: undefined,
      sklikPositionMobile: undefined,
      adsensePositionDesktop: undefined,
      adsensePositionMobile: undefined,
      sspPositionDesktop: undefined,
      sspPositionMobile: undefined,
      minHeight: 300,
    },

    'articleBottom': {
      name: AdPositionName.articleBottom,
      sklikPositionDesktop: sklikPositionId.txtSklik,
      sklikPositionMobile: sklikPositionId.smrMobilText,
      adsensePositionDesktop: adsensePositionId.wallpaper,
      adsensePositionMobile: adsensePositionId.wallpaper,
      sspPositionDesktop: sspPositionId.txtSklik,
      sspPositionMobile: sspPositionId.smrMobilText,
      minHeight: 300,
    },

    'native': {
      name: AdPositionName.native,
      sklikPositionDesktop: undefined,
      sklikPositionMobile: undefined,
      adsensePositionDesktop: undefined,
      adsensePositionMobile: undefined,
      sspPositionDesktop: sspPositionId.native,
      sspPositionMobile: sspPositionId.nativeMobil,
      minHeight: 300,
    },

    'footerboard': {
      name: AdPositionName.footerboard,
      sklikPositionDesktop: sklikPositionId.footerboard,
      sklikPositionMobile: undefined,
      adsensePositionDesktop: adsensePositionId.footerboard,
      adsensePositionMobile: adsensePositionId.footerboard,
      sspPositionDesktop: sspPositionId.footerboard,
      sspPositionMobile: undefined,
      minHeight: 310,
    }
  }
}
