import {AfterViewInit, Component, Inject, PLATFORM_ID} from '@angular/core';
import { Gallery, GalleryInterface, ImageInterface } from "../drupal/entities";
import {ActivatedRoute, Router} from "@angular/router";
import {EntityMetaService} from "../drupal/entity-meta.service";
import {Drupal7Service} from "../drupal/drupal7.service";
import {Drupal7mappingService} from "../drupal/drupal7mapping.service";
import {tap} from "rxjs";
import {GalleryResult} from "../drupal/resultTypes";
import {AdPositionName} from "../ads/ad-position/ad-position-map";
import {AdServeServiceService} from "../ads/ad-serve/ad-serve-service.service";
import {isPlatformBrowser} from "@angular/common";
import {environment} from "../../environments/environment";
import {SsspService} from "../seznam/sssp.service";

@Component({
  selector: 'app-gallery-detail',
  templateUrl: './gallery-detail.component.html',
  styleUrls: ['./gallery-detail.component.css']
})
export class GalleryDetailComponent implements AfterViewInit {
  gallery: GalleryInterface | undefined;
  images: Array<ImageInterface> | undefined;
  titlePath: string | null = null;
  backRouterLink: string | undefined;
  protected readonly AdPositionName = AdPositionName;

  lightGallerySettings = {
    selector: '.gallery-item',
    counter: false,
    plugins: [],
    download: false,
    height: '90%',
  };

  public readonly isBrowser: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private entityMeta: EntityMetaService,
    private drupal7: Drupal7Service,
    private drupal7mapping: Drupal7mappingService,
    private adServe: AdServeServiceService,
    private sssp: SsspService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      tap(params => {
        this.titlePath = params.get('titlepath');
        this.drupal7.getNode(this.titlePath).pipe(
          tap((data: GalleryResult) => {
            this.gallery = {
              id: data.nid,
              title: data.title,
              path: '',
              routerLink: '/fotogalerie/' + this.drupal7.generateNodePath(data.nid, data.title),
            }
            this.gallery.fullUrl = environment.baseUrl + this.gallery.routerLink;
            this.images = this.drupal7mapping.mapImages(data.field_images.und);
            this.entityMeta.setMeta(new Gallery(this.gallery));
          })
        ).subscribe(params => {})
      })
    ).subscribe(params => {})

    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        // @ts-ignore
        const articleNid = parseInt(fragment.split('/').slice(-1).pop());
        if (articleNid > 0) {
          this.drupal7.getNodeById(articleNid).subscribe(data => {
            const article = this.drupal7mapping.mapArticle(data);
            this.backRouterLink = article.routerLink;
          })
        }
      }
    })
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this.sssp.setPageViewId();

      this.adServe.serveAds([
        AdPositionName.square,
      ]);
    }
  }
}
