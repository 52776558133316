<div class="max-w-[1060px] mx-auto">
  <div class="block md:flex p-6 md:space-x-12">
    <div class="w-full md:w-2/3 md:flex-1">
      <ng-template [ngIf]="article" [ngIfElse]="loader">
        <h1>Diskuse: {{article.title}}</h1>
        <h2><a routerLink="{{article.routerLink}}"><la-icon icon="long-arrow-alt-left"></la-icon> Zpět do článku</a></h2>
        <div id="discussion"></div>
      </ng-template>

      <ng-template #loader>
        <div class="loader"><la-icon class="animate-spin" icon="spinner" size="2x"></la-icon></div>
      </ng-template>
    </div>
    <div class="w-full md:w-1/3 md:max-w-[300px] flex items-stretch">
      <app-sidebar class="relative w-full"></app-sidebar>
    </div>
  </div>
</div>
