import { Component } from '@angular/core';
import {Drupal7Service} from "../drupal/drupal7.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-newsletter-confirmation',
  templateUrl: './newsletter-confirmation.component.html',
  styleUrls: ['./newsletter-confirmation.component.css']
})
export class NewsletterConfirmationComponent {
  response: any;
  subscriptionHash: string|undefined;

  constructor(
    private route: ActivatedRoute,
    private drupal7: Drupal7Service
  ) {}

  ngOnInit(): void {
    this.subscriptionHash = this.route.snapshot.params['hash'];
  }

  onSubmit() {
    if (this.subscriptionHash) {
      this.drupal7.confirmNewsletter(this.subscriptionHash).subscribe(response => {
        this.response = response;
      });
    }
    else {
      this.response = {'status': 'error'};
    }
  }
}
