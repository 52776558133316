<div class="max-w-[1060px] mx-auto">
  <div class="block md:flex p-6 md:space-x-12">
    <div class="w-full md:w-2/3 md:flex-1 text-center">
      <h1>Potvrdit odběr newsletteru</h1>
      <p>Klikněte na tlačítko "Odebírat" níže a budete informováni o dění ve Vašem okolí.</p>
      <p>Z odběru novinek se budete moci kdykoliv odhlásit.</p>
      <form (ngSubmit)="onSubmit()" #confirmationForm="ngForm">
        <button *ngIf="!response || response.status != 'success'" type="submit" class="btn-large"><la-icon icon="check" class="pr-2"></la-icon> Odebírat</button>
      </form>
      <div *ngIf="response && response.status == 'error'" class="alert alert-error">
        Potvrzení odběru newsletteru skončilo chybou. Zkuste to, prosím, později.
      </div>
      <div *ngIf="response && response.status == 'success'" class="alert alert-success">
        Děkujeme, úspěšně jste potvrdili odběr newsletteru. <a routerLink="">Pokračujte na titulní stranu</a>.
      </div>
    </div>
  </div>
</div>
